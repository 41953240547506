<template>
  <gli-dialog
    :title="isNew ? $t('Create new user') : $t('Edit user')"
    :isNew="isNew"
    :editedObject="editedUser"
    @delete="deleteUser(editedUser._id)"
    @save="save"
    @cancel="cancel"
  >
    <v-row dense>
      <v-col cols="12">
        <form autocomplete="off">
          <gli-text-field
            v-model="editedUser.username"
            :label="$t('Username')"
            required
            :rules="[rules.required]"
            :autofocus="isNew"
            :maxLength="25"
          ></gli-text-field>
        </form>
      </v-col>
      <v-col cols="12">
        <form autocomplete="off">
          <gli-text-field
            v-model="editedUser.password"
            :label="$t('Password')"
            :type="showPwd ? 'text' : 'password'"
            :append-icon="showPwd ? 'visibility_off' : 'visibility'"
            @click:append="showPwd = !showPwd"
            :rules="usePwRules ? [rules.required, rules.password] : []"
            :maxLength="50"
          ></gli-text-field>
        </form>
      </v-col>
      <v-col cols="12">
        <gli-text-field
          v-model="editedUser.firstName"
          :label="$t('First name')"
          :maxLength="50"
        ></gli-text-field>
      </v-col>
      <v-col cols="12">
        <gli-text-field
          v-model="editedUser.lastName"
          :label="$t('Last name')"
          :maxLength="50"
        ></gli-text-field>
      </v-col>
      <v-col cols="12">
        <form autocomplete="off">
          <gli-text-field
            v-model="editedUser.email"
            :label="$t('Email')"
            :rules="[rules.email]"
            :maxLength="50"
          ></gli-text-field>
        </form>
      </v-col>
      <v-col cols="12">
        <v-select
          :items="Object.values(roles)"
          v-model="editedUser.role"
          :label="$t('Role')"
          :rules="[rules.required]"
          clearable
        ></v-select>
      </v-col>
      <v-col cols="12" v-if="isSuperAdmin">
        <v-select
          item-text="name"
          item-value="id"
          :items="organizations"
          v-model="editedUser.organizationId"
          :label="$t('Organization')"
          clearable
        ></v-select>
      </v-col>
    </v-row>
  </gli-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../mixins/rules';
import constants from '~/shared/constants';

export default {
  name: 'UserDialog',

  data() {
    return {
      showPwd: false
    };
  },
  computed: {
    ...mapGetters('users', ['isNew', 'editedUser', 'users']),
    ...mapGetters('organization', ['organizations']),
    ...mapGetters('account', ['isSuperAdmin']),
    roles: {
      get() {
        return constants.ROLES;
      }
    },

    usePwRules() {
      return (
        this.isNew ||
        (this.editedUser && !!this.editedUser.password && this.editedUser.password.length > 0)
      );
    }
  },

  async mounted() {
    await this.getAllOrganizations();
  },

  methods: {
    ...mapActions('organization', { getAllOrganizations: 'getAll' }),
    ...mapActions('users', {
      save: 'save',
      cancel: 'cancel',
      deleteUser: 'delete'
    })
  },
  mixins: [rules]
};
</script>
